import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import Button from '../components/Button';
import { cwTheme } from '../theme/theme.js';
import SignUpForm from '../components/SignUpForm';

const PostSection = styled.section`padding: 2rem 0;`;

const PostContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const PostMainImage = styled.div`
	margin: 0 auto;
	display: block;
	max-width: 800px;
	width: 100%;
`;

const PostMeta = styled.div`
	text-align: left;
	max-width: 700px;
	margin: 0 auto;
	width: 100%;
`;

const PostTitle = styled.h3`
	line-height: 1.2em;
	margin-top: 0;
`;

const PostDate = styled.span`
	text-transform: uppercase;
	display: block;
	color: ${cwTheme.secondaryBlue};
	font-weight: 600;
	font-size: 1.25em;
`;

const PostTime = styled.span`
	text-transform: uppercase;
	display: block;
	color: ${cwTheme.secondaryBlue};
	font-weight: 700;
	font-size: 1.25em;
`;

const PostBody = styled.div`
	max-width: 680px;
	margin: 0 auto;
	text-align: left;
	p {
		font-weight: 400;
	}

	img {
		max-width: 100%;
		margin: 2rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${cwTheme.sansSerif};
	}
`;

const PreviousLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;

  text-decoration: none;
  color: ${cwTheme.black};
  margin-right: 1rem;
  font-weight: 700;
  
  max-width: 300px;
  text-align: right;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  h6{
	   margin-top: 0;
	   font-weight: 600;
  }

  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
  
`;

const NextLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
 
  text-decoration: none;
  color: ${cwTheme.black};
  margin-left: 1rem;
  font-weight: 700;
  
  text-align: left;
  max-width: 300px;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  display: flex;
  flex-direction: column;
  align-content: space-between;
  h6{
	margin-top: 0;
	font-weight: 600;
  }
  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
	
`;

const PostLinks = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
`;

const PostDetails = styled.div`padding: 10px;`;

export default ({ data, pageContext }) => {
	const liveEvent = data.contentfulEvent;

	const previous = pageContext.prev;
	const next = pageContext.next;
	const { basePath } = pageContext;

	const { title, date, timeText } = data.contentfulEvent;
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={title} description={liveEvent.description.childMarkdownRemark.excerpt} />
				<PostSection>
					<PostContainer>
						<PostMeta>
							<PostMainImage>
								<Img backgroundColor={cwTheme.lightBlue} sizes={liveEvent.mainImage.fluid} />
							</PostMainImage>
							<PostDetails>
								<PostTitle>{title}</PostTitle>
								<PostDate>{timeText + ' on ' + date}</PostDate>

								<PostBody
									dangerouslySetInnerHTML={{ __html: liveEvent.description.childMarkdownRemark.html }}
								/>
								<Button
									link={liveEvent.link}
									hoverColor={cwTheme.primaryHover}
									color={cwTheme.primaryBlue}
									style={{ alignSelf: 'flex-start', marginBottom: '.5rem' }}
								>
									{liveEvent.linkText}
								</Button>
							</PostDetails>
						</PostMeta>

						<PostLinks>
							{previous ? (
								!(previous.title == 'dummy post') ?
									<PreviousLink to={`${basePath}/${previous.id}/`}>
										<span>&#8592; Previous</span>
										<h6>{previous.title}</h6>
									</PreviousLink> : <PreviousLink />
							) : (
									<PreviousLink />
								)}
							{next ? (
								!(next.title == 'dummy post') ?
									<NextLink to={`${basePath}/${next.id}/`}>
										<span>Next &#8594;</span>
										<h6>{next.title}</h6>
									</NextLink>
									:
									<NextLink />
							) : (
									<NextLink />
								)}
						</PostLinks>
					</PostContainer>
				</PostSection>

				<SignUpForm header={"Don't miss an event! Receive the next one in your inbox"} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($slug: String!) {
		contentfulEvent(id: { eq: $slug }) {
			description {
				childMarkdownRemark {
					html
					excerpt
					timeToRead
				}
			}
			date(formatString: "MMMM DD, YYYY")
			title
			link
			linkText
			timeText
			mainImage {
				fluid(maxWidth: 1200, maxHeight: 600, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
		}
	}
`;
